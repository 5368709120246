import {
  Work,
  formatIsbdTitle,
  isInteractiveWork,
  isMovingImageWork,
  isMusicalWork,
} from "@biblioteksentralen/cordata";
import { BookRedia, GameRedia } from "@biblioteksentralen/icons";
import { BoxProps, ChakraProps, Flex, Grid, Image, colors } from "@biblioteksentralen/react";
import { Music, Video } from "react-feather";
import { useSitePalette } from "../../utils/useSitePalette";
import { CoverImageQuality, chooseCoverImageQuality, isValidCoverImage } from "../cordata/coverImage";
import { WorkToBeRepresented } from "../cordata/manifestations";
import { SortableManifestation } from "../types";
import { usePreferredWorkPreviewData } from "./works/WorkPreview";

interface Props {
  work: WorkToBeRepresented;
  representativeManifestation?: SortableManifestation;
  quality?: CoverImageQuality;
  usePlaceholder?: boolean;
}

const commonStyling: ChakraProps = {
  boxShadow: "md",
  borderRadius: "lg",
  _groupHover: { transition: ".5s", transform: "scale(0.965)" },
  transition: "0.9s",
};

export const WorkCoverImage = ({
  work,
  representativeManifestation: representativeManifestationFromProps,
  quality,
  usePlaceholder,
  ...boxProps
}: Props & BoxProps) => {
  const palette = useSitePalette();
  const fallbackRepresentativeManifestation = usePreferredWorkPreviewData(work).representativeManifestation;
  const representativeManifestation = representativeManifestationFromProps ?? fallbackRepresentativeManifestation;

  const src = chooseCoverImageQuality(representativeManifestation?.coverImage, quality ?? "thumbnail");
  const blurSrc = chooseCoverImageQuality(representativeManifestation?.coverImage, "blur");

  const alt = formatIsbdTitle(work);
  const Icon = getPlaceholderIcon(work);

  if (usePlaceholder || !isValidCoverImage(src))
    return (
      <Grid
        {...commonStyling}
        css="aspect-ratio: 0.7;"
        background={palette.colors.lightaccent1.css.background}
        color={colors.white}
        placeItems="center"
        minWidth="6rem"
        aria-hidden
        {...boxProps}
      >
        <Icon fontSize="3rem" size="3rem" />
      </Grid>
    );

  return (
    <Flex {...boxProps}>
      <Image
        fallback={
          // Egen komponent for å få blur på fallbackbilde. Hvis vi ikke trenger blur kan vi bruke fallbackSrc={blurSrc} istedenfor
          <Image
            {...commonStyling}
            src={blurSrc}
            width="100%"
            height="auto"
            filter="blur(.15rem)"
            alt={alt}
            display="block"
          />
        }
        {...commonStyling}
        src={src}
        alt={alt}
        display="block"
        height="auto"
        width="100%"
      />
    </Flex>
  );
};

const getPlaceholderIcon = (work: Work) => {
  if (isMusicalWork(work)) return Music;
  if (isMovingImageWork(work)) return Video;
  if (isInteractiveWork(work)) return GameRedia;
  return BookRedia;
};
